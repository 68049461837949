@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  font-size: 10px;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

@media (prefers-color-scheme: dark) {
  html {
    color-scheme: dark;
  }

  body {
    color: white;
    background: black;
  }
}

/* Add pointer cursor to native search clear buttons */
input[type='search']::-webkit-search-decoration:hover,
input[type='search']::-webkit-search-cancel-button:hover {
  cursor: pointer;
}

/* Hide input type number arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Autocomplete bg & color */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #f4f4f4 inset !important;
  -webkit-text-fill-color: black !important;
}

input:focus {
  box-shadow: none !important;
}

textarea:focus {
  box-shadow: none !important;
  border: 2px solid #242d5f;
}

::-webkit-calendar-picker-indicator {
  filter: invert(48%) sepia(30%) saturate(0%) hue-rotate(203deg) brightness(90%)
    contrast(95%);
  cursor: pointer;
}

::-webkit-calendar-picker-indicator:hover {
  filter: invert(60%) sepia(100%) saturate(1854%) hue-rotate(3deg)
    brightness(107%) contrast(106%);
}

.max-w-default {
  max-width: 150rem;
}

.Typewriter__cursor {
  color: rgba(240, 98, 34, 1) !important;
}

.noScroll::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}

::-webkit-scrollbar {
  width: 1rem;
  padding-left: 10px;
  height: 1rem;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgb(0, 0, 0, 0);
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #808080;
}

textarea {
  padding: 1.4rem 2rem;
  font-size: 1.5rem;
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
  /*remove the resize handle on the bottom right*/
}

/* CK Editor Config */
.ck-toolbar__items {
  flex-wrap: wrap !important;
}

.ck-content {
  background-color: inherit !important;
  width: 100% !important;
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.ck-rounded-corners .ck.ck-editor__main > .ck-editor__editable,
.ck.ck-editor__main > .ck-editor__editable.ck-rounded-corners {
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 1px !important;
}

.ck-editor__editable {
  min-height: 20rem !important;
}

.ck-editor {
  width: 100%;
  max-width: 100% !important;
}

.ck.ck-reset.ck-editor {
  width: 100%;
  max-width: 100% !important;
}

.ais-Highlight-highlighted {
  background: none;
  font-weight: 600;
  /* color: #f06222; */
}

#tsparticles {
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
  padding: 0;
  z-index: 0;
}

/* Scroll smooth doesn't work in safari so this prevents BaseProcess from breaking on safari */
.scroll-smooth-no-safari {
  scroll-behavior: smooth;
}

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) and (stroke-color: transparent) {
    .scroll-smooth-no-safari {
      scroll-behavior: auto;
    }

    .overflow-clip {
      overflow: hidden !important;
    }
  }
}

.editor-content > blockquote,
.editor-content > dl,
.editor-content > dd,
.editor-content > h1,
.editor-content > h2,
.editor-content > h3,
.editor-content > h4,
.editor-content > h5,
.editor-content > h6,
.editor-content > hr,
.editor-content > figure,
.editor-content > p,
.editor-content > pre {
  margin: revert;
}
.editor-content > ol,
.editor-content > ul {
  list-style: revert;
  margin: revert;
  padding: revert;
}
.editor-content > table {
  border-collapse: revert;
}

.editor-content h1 {
  font-size: 3.2rem;
  font-weight: 600;
}
.editor-content h2 {
  font-size: 2.4rem;
  font-weight: 600;
}
.editor-content h3 {
  font-size: 1.8rem;
  font-weight: 600;
}
.editor-content a {
  color: #EF9021;
}

input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}

.list-disc-children > ol,
ul,
menu {
  list-style: disc;
  margin-block-start: 0.5rem;
  padding-inline-start: 2rem;
}

ol {
  list-style: decimal;
  margin-block-start: 0.5rem;
  padding-inline-start: 2rem;
}

.primary-links a {
  font-size: 16px;
  color: rgb(36 46 95);
  font-weight: 600;
  text-decoration: underline;
}
.formatquill {
  font-size: 1.5rem;
}

.formatquill strong,
.formatQuill b {
  font-weight: 600 !important;
}